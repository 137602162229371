<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">
                {{ $t("EQUIPMENTS.EDIT_EQUIPMENT") }}
              </h3>
            </div>
            <div class="col-4 text-right">
              <base-button @click="goBack" type="button" class="btn btn-sm btn-primary">
                {{ $t("COMMON.RETURN_TO_LIST") }}
              </base-button>
            </div>
          </div>
          <div class="card-body">
            <equipment-form
              :loading="loading"
              :equipmentData="equipment"
              :formErrors="formErrors"
              @equipmentSubmitted="handleSubmit"
              @formChanged="() => (alertLeave = true)"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import EquipmentForm from "./partials/EquipmentForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultEquipment from "./defaultEquipment";

export default {
  layout: "DashboardLayout",

  components: { EquipmentForm },

  mixins: [alertLeave],

  data() {
    return {
      equipment: cloneDeep(defaultEquipment),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      this.loading = true;
      try {
        const id = this.$route.params.id;
        await this.$store.dispatch("equipments/get", id);
        this.equipment = this.$store.getters["equipments/equipment"];
        this.loading = false;
        if (this.equipment.cafeteria) {
          if (!this.equipment.cafeteria.id) {
            delete this.equipment.cafeteria;
          }
        }
        if (this.equipment.serviceCenter) {
          if (!this.equipment.serviceCenter.id) {
            delete this.equipment.serviceCenter;
          }
        }
        if (this.equipment.establishment) {
          if (!this.equipment.establishment.id) {
            delete this.equipment.establishment;
          }
        }
        if (this.equipment.cafeteria) {
          if (!this.equipment.cafeteria.id) {
            delete this.equipment.cafeteria;
          }
        }
        if (this.equipment.categoryEquipment) {
          if (!this.equipment.categoryEquipment.id) {
            delete this.equipment.categoryEquipment;
          }
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });

        this.loading = false;
      }
    },

    goBack() {
      this.$router.push({ name: "List Equipments" });
    },

    viewEquipment() {
      this.alertLeave = false;
      this.$router.push({
        name: "View Equipment",
        params: { id: this.equipment.id },
      });
    },

    async handleSubmit(equipment) {
      this.loading = true;
      const equipmentData = cloneDeep(equipment);
      if (equipmentData.serviceCenter) {
        if (!equipmentData.serviceCenter.id) {
          delete equipmentData.serviceCenter;
        }
      }
      if (equipmentData.establishment) {
        if (!equipmentData.establishment.id) {
          delete equipmentData.establishment;
        }
      }
      if (equipmentData.cafeteria) {
        if (!equipmentData.cafeteria.id) {
          delete equipmentData.cafeteria;
        }
      }
      if (equipmentData.categoryEquipment) {
        if (!equipmentData.categoryEquipment.id) {
          delete equipmentData.categoryEquipment;
        }
      }
      try {
        await this.$store.dispatch("equipments/update", equipmentData);
        this.$notify({
          type: "success",
          message: this.$t("EQUIPMENTS.EQUIPMENT_UPDATED"),
        });
        this.viewEquipment();
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
